import React, { useEffect } from "react";
import {
    Container,
    Nav,
    Navbar,
    Image
} from "react-bootstrap";
import { useLocation } from 'react-router-dom';

import CatalyzonLogo from "../../assets/images/catalyzon-logo.png";

import "../../assets/css/navbar-solution.css";
import "../../assets/css/responsive.css";

const NavbarSolution = () => {

    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    const handlePortfolioClick = () => {
        if (location.pathname !== '/') {
            window.location.href = '/#impact-content';
        } else {
            document.getElementById('impact-content').scrollIntoView({ behavior: 'smooth' });
        }
    };
    
    const handleMediaClick = () => {
        if (location.pathname !== '/') {
            window.location.href = '/#media-content';
        } else {
            document.getElementById('media-content').scrollIntoView({ behavior: 'smooth' });
        }
    };
    
    const handleContactClick = () => {
        if (location.pathname !== '/') {
            window.location.href = '/#contact-content';
        } else {
            document.getElementById('contact-content').scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (

        <Navbar className="navbar navbar-solution" expand="lg" fixed="top">
            <Container>
                <Navbar.Brand className="nav-brand" href="#">
                    <Image src={CatalyzonLogo}/>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">
                        <Nav.Link onClick={() => window.location.href = '/'}>About Us</Nav.Link>
                        <Nav.Link onClick={() => window.location.href = '/solution'}>Solutions</Nav.Link>
                        <Nav.Link onClick={handlePortfolioClick}>Portfolio</Nav.Link>
                        <Nav.Link onClick={handleMediaClick}>Media</Nav.Link>
                        <Nav.Link onClick={handleContactClick}>Contact</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>

    );

};

export default NavbarSolution;