import React, { useState, useEffect } from "react";
import {
    Container,
    Nav,
    Navbar,
    Image
} from "react-bootstrap";
import { useLocation } from 'react-router-dom';

import CatalyzonLogo from "../../assets/images/catalyzon-logo.png";

import "../../assets/css/home.css";
import "../../assets/css/responsive.css";

const NavbarGeneral = () => {

    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const isScrolled = window.scrollY > 50;
            if (isScrolled !== scrolled) {
                setScrolled(isScrolled)
            }
        }
        
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [scrolled]);

    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    const handlePortfolioClick = () => {
        if (location.pathname !== '/') {
            window.location.href = '/#impact-content';
        } else {
            document.getElementById('impact-content').scrollIntoView({ behavior: 'smooth' });
        }
    };
    
    const handleMediaClick = () => {
        if (location.pathname !== '/') {
            window.location.href = '/#media-content';
        } else {
            document.getElementById('media-content').scrollIntoView({ behavior: 'smooth' });
        }
    };
    
    const handleContactClick = () => {
        if (location.pathname !== '/') {
            window.location.href = '/#contact-content';
        } else {
            document.getElementById('contact-content').scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (

        <Navbar className={scrolled ? "navbar-scrolled" : "navbar"} expand="lg" fixed="top">
            <Container>
                <Navbar.Brand className="nav-brand" href="#">
                    <Image src={CatalyzonLogo}/>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">
                        <Nav.Link onClick={() => window.location.href = '/'} className={scrolled ? "nav-link-scrolled" : "nav-link"}>About Us</Nav.Link>
                        <Nav.Link onClick={() => window.location.href = '/solution'} className={scrolled ? "nav-link-scrolled" : "nav-link"}>Solutions</Nav.Link>
                        <Nav.Link onClick={handlePortfolioClick} className={scrolled ? "nav-link-scrolled" : "nav-link"}>Portfolio</Nav.Link>
                        <Nav.Link onClick={handleMediaClick} className={scrolled ? "nav-link-scrolled" : "nav-link"}>Media</Nav.Link>
                        <Nav.Link onClick={handleContactClick} className={scrolled ? "nav-link-scrolled" : "nav-link"}>Contact</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>

    );

};

export default NavbarGeneral;